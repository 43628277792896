.App {
  text-align: center;
 
}
.background{
  width: 100vw;
  height: 100vh;
  min-height: 100vh;
  /* display: flex; */
  /* align-self: center; */
  /* justify-content: center; */
  /* position: fixed;   */
  background-image: url(../src/background/bg2.jpg) ;
  /* background-repeat: repeat; */
  background-size: cover;
  overflow-y: auto;
}
.sideback{
  min-height: 100%;
  display: flex;
  flex-direction: column;
  padding: 70px 50px;
  position: relative;
  background-color: #ffffff;
  opacity:0.9;
  @media only screen and (max-width: 767px) {
    width: 100%;
    padding: 70px 20px;
  }
  
  
  }

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color:white;
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.TopColor{
  font-family: 'Prompt', sans-serif;
  font-size: 95%;
  color: white;
  position: relative;
  display: flex;

}
font {
  font-family: 'Prompt', sans-serif
}

.alert {
  color: red;
  font-family: 'Prompt', sans-serif;
  font-size: '80%';
  line-height: 1.2;
  margin: 20px 0;
  margin-left: 20px;
  position: relative;
  display: flex;
  align-items: center;
}

h1, h2, h3, h4, h5, h6 {
font-family: 'Prompt', sans-serif
}

ion-icon {
  font-size: 24px;
}

.bodyFont{
    font-size: 20px;
   
}
@media (min-width: 320px) { /*for small mobile*/
  responfont {
    font-size: calc(1.9vh + 1.9vw);
    font-family: 'Prompt', sans-serif; 
  }
  gridfont{
    font-size: calc(1.6vh + 1.6vw);
    font-family: 'Prompt', sans-serif;
  }
}
@media (min-width: 600px) { /*for large mobile*/
  responfont {
    font-size: calc(1.5vh + 1.5vw);
    font-family: 'Prompt', sans-serif; 
  }
  gridfont{
    font-size: calc(1.5vh + 1.5vw);
    font-family: 'Prompt', sans-serif;
  }
}
@media (min-width: 768px) { /*for ipad , ipad pro , tablet*/
  responfont {
    font-size: calc(1.3vh + 1.3vw);
    font-family: 'Prompt', sans-serif; 
  }
  gridfont{
    font-size: calc(1.3vh + 1.3vw);
    font-family: 'Prompt', sans-serif;
    margin: 2%
  }
}
@media (min-width: 1024px) { /*for Computer*/
  responfont {
    font-size: 2.1vw;
    font-family: 'Prompt', sans-serif; 
  }
  gridfont{
    font-size: calc(1.3vh + 1.1vw);
    font-family: 'Prompt', sans-serif;
    margin: 2%
  }
}
@media (min-width: 1281px) { /*for Computer*/
  responfont {
    font-size: 1.6vw;
    font-family: 'Prompt', sans-serif; 
  }
  gridfont{
    font-size: calc(1.1vh + 1.1vw);
    font-family: 'Prompt', sans-serif;
    margin: 2%
  }
}
@media (min-width: 1601px) { /*for Computer*/
  responfont {
    font-size: 1.3vw;
    font-family: 'Prompt', sans-serif; 
  }
  gridfont{
    font-size: calc(1.2vh + 1.2vw);
    font-family: 'Prompt', sans-serif;
  }
}
