body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
 
}
.background{
  width: 100vw;
  height: 100vh;
  min-height: 100vh;
  /* display: flex; */
  /* align-self: center; */
  /* justify-content: center; */
  /* position: fixed;   */
  background-image: url(/static/media/bg2.58973e99.jpg);
  /* background-repeat: repeat; */
  background-size: cover;
  overflow-y: auto;
}
.sideback{
  min-height: 100%;
  display: flex;
  flex-direction: column;
  padding: 70px 50px;
  position: relative;
  background-color: #ffffff;
  opacity:0.9;
  @media only screen and (max-width: 767px) {
    width: 100%;
    padding: 70px 20px;
  }
  
  
  }

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color:white;
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.TopColor{
  font-family: 'Prompt', sans-serif;
  font-size: 95%;
  color: white;
  position: relative;
  display: flex;

}
font {
  font-family: 'Prompt', sans-serif
}

.alert {
  color: red;
  font-family: 'Prompt', sans-serif;
  font-size: '80%';
  line-height: 1.2;
  margin: 20px 0;
  margin-left: 20px;
  position: relative;
  display: flex;
  align-items: center;
}

h1, h2, h3, h4, h5, h6 {
font-family: 'Prompt', sans-serif
}

ion-icon {
  font-size: 24px;
}

.bodyFont{
    font-size: 20px;
   
}
@media (min-width: 320px) { /*for small mobile*/
  responfont {
    font-size: calc(1.9vh + 1.9vw);
    font-family: 'Prompt', sans-serif; 
  }
  gridfont{
    font-size: calc(1.6vh + 1.6vw);
    font-family: 'Prompt', sans-serif;
  }
}
@media (min-width: 600px) { /*for large mobile*/
  responfont {
    font-size: calc(1.5vh + 1.5vw);
    font-family: 'Prompt', sans-serif; 
  }
  gridfont{
    font-size: calc(1.5vh + 1.5vw);
    font-family: 'Prompt', sans-serif;
  }
}
@media (min-width: 768px) { /*for ipad , ipad pro , tablet*/
  responfont {
    font-size: calc(1.3vh + 1.3vw);
    font-family: 'Prompt', sans-serif; 
  }
  gridfont{
    font-size: calc(1.3vh + 1.3vw);
    font-family: 'Prompt', sans-serif;
    margin: 2%
  }
}
@media (min-width: 1024px) { /*for Computer*/
  responfont {
    font-size: 2.1vw;
    font-family: 'Prompt', sans-serif; 
  }
  gridfont{
    font-size: calc(1.3vh + 1.1vw);
    font-family: 'Prompt', sans-serif;
    margin: 2%
  }
}
@media (min-width: 1281px) { /*for Computer*/
  responfont {
    font-size: 1.6vw;
    font-family: 'Prompt', sans-serif; 
  }
  gridfont{
    font-size: calc(1.1vh + 1.1vw);
    font-family: 'Prompt', sans-serif;
    margin: 2%
  }
}
@media (min-width: 1601px) { /*for Computer*/
  responfont {
    font-size: 1.3vw;
    font-family: 'Prompt', sans-serif; 
  }
  gridfont{
    font-size: calc(1.2vh + 1.2vw);
    font-family: 'Prompt', sans-serif;
  }
}

.Dropdown-root {
    position: relative;
  }
  
  .Dropdown-control {
    position: relative;
    overflow: hidden;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 2px;
    box-sizing: border-box;
    color: #333;
    cursor: default;
    outline: none;
    padding: 8px 52px 8px 10px;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
  }
  
  .Dropdown-control:hover {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  }
  
  .Dropdown-arrow {
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    content: ' ';
    display: block;
    height: 0;
    margin-top: -ceil(2.5);
    position: absolute;
    right: 10px;
    top: 14px;
    width: 0
  }
  
  .is-open .Dropdown-arrow {
    border-color: transparent transparent #999;
    border-width: 0 5px 5px;
  }
  
  .Dropdown-menu {
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    margin-top: -1px;
    max-height: 200px;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 1000;
    -webkit-overflow-scrolling: touch;
    
  }
  
  .Dropdown-menu .Dropdown-group > .Dropdown-title{
    padding: 8px 10px;
    color: rgba(51, 51, 51, 1);
    font-weight: bold;
    text-transform: capitalize;
  }
  
  .Dropdown-option {
    box-sizing: border-box;
    color: rgba(51, 51, 51, 0.8);
    cursor: pointer;
    display: block;
    padding: 8px 10px;
  }
  
  .Dropdown-option:last-child {
    border-bottom-right-radius: 2px;
     border-bottom-left-radius: 2px;
  }
  
  .Dropdown-option:hover {
    background-color: #f2f9fc;
    color: #333;
  }
  
  .Dropdown-option.is-selected {
    background-color: #f2f9fc;
    color: #333;
  }
  
  .Dropdown-noresults {
    box-sizing: border-box;
    color: #ccc;
    cursor: default;
    display: block;
    padding: 8px 10px;
  }
